import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";

export default function ExpedientCreate() {

	const today = (new Date()).getFullYear() + '-' + (((new Date()).getMonth() + 1) < 10 ? '0' + ((new Date()).getMonth() + 1).toString() :  (new Date()).getMonth() + 1  ) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate());

	const [ expedient_number, setExpedientNumber ] = useState("");
	const [ expedient_date, setExpedientDate ] = useState("");
	const [ year, setYear ] = useState("");
	const [ person_number_id, setPersonNumberId ] = useState("");
	const [ state, setState ] = useState("");
	const [ closed_date, setClosedDate ] = useState("");

	const [ date, setDate ] = useState(today);
	const [ note, setNote ] = useState("");
	const [ file, setFile ] = useState(null);

	const [ totalItems, setTotalItems ] = useState(0);
	const itemsPerPage = 30;
	const [ expedientnotes, setExpedientnotes ] = useState(null);
	const [ contingency, setContingency ] = useState("");
	const [ description, setDescription ] = useState("");
	const [ created_at, setCreatedAt ] = useState("");
	const [ certificate, setCertificate ] = useState(null);
	const [ name_comp, setNameComp ] = useState(null);
	const [ capital, setCapital ] = useState(null);

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	const {id} = useParams();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}

		fetchExpedient();
		fetchExpedientnotes(0);
	},[]);


	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % totalItems;
		fetchExpedientnotes(newOffset);
	};

	const fetchExpedientnotes = async (offset) => {

		await axios.get("https://api-rnf.seguronline.es/expedientnote/paginate/" + id + "/" + offset, {
			headers: {Authorization: `${cookies.token}`}
		}).then(({data}) => {
			setExpedientnotes(data.expedientnotes);
			setTotalItems(data.totalItems);
		});
	};

	const pageCount = Math.ceil(totalItems / itemsPerPage);


	const fetchExpedient = async () => {
		await axios.get(`https://api-rnf.seguronline.es/expedient/${id}`,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

			setExpedientNumber(data[0].expedient_number);
			setExpedientDate(data[0].expedient_date);
			setYear(data[0].year);
			setPersonNumberId(data[0].person_number_id);
			setState(data[0].state);
			setClosedDate(data[0].closed_date);
			setContingency(data[0].contingency);
			setDescription(data[0].description);
			setCreatedAt(data[0].created_at.substring(0,10));
			setCertificate(data[0].certificates);
			setNameComp(data[0].name_comp);
			data[0].contingency === 1 ? setCapital(data[0].death) :
				data[0].contingency === 2 ? setCapital(data[0].absolute_incapacity) :
					data[0].contingency === 3 ? setCapital(data[0].total_incapacity) :
						data[0].contingency === 4 ? setCapital(data[0].partial_incapacity) : setCapital(null) ;
		});
	};


	const editExpedient = async (e) => {
		e.preventDefault();

		const raw = {
			'expedient_number': expedient_number,
			'expedient_date': expedient_date,
			'year': year,
			'person_number_id': person_number_id,
			'state': state,
			'closed_date': closed_date,
			'contingency': contingency,
			'description': description,
		};

		await axios.patch(`https://api-rnf.seguronline.es/expedient/${id}`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			alert("Datos guardados");
		}).catch(({response:{data}})=>{
			alert("Error en los datos");
		});
	};

	const expedientNotes = async (e) => {
		e.preventDefault();

		const raw = {
			'date': today,
			'note': note,
			'expedient_id': id,
			'filename': '',
		};

		await axios.post(`https://api-rnf.seguronline.es/expedientnote`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

				if(file) {
					axios.post(`https://api-rnf.seguronline.es/expedientnote/file/${data.id}`, file, {
						headers: {
							Authorization: `${cookies.token}`
						}
					}).then(({data}) => {

						window.location.reload();

					}).catch(({response: {data}}) => {
						alert("Error al subir el documento");
					});
				}else{

					fetchExpedientnotes(0);

					setDate(today);
					setNote("");

				}

		}).catch(({response:{data}})=>{
			alert("Error en los datos");
		});
	};

	const expedientDocs = async (e) => {
		e.preventDefault();

		const raw = {
			'date': date,
			'note': '',
			'expedient_id': id,
			'filename': (file ? file.name : ''),
		};

		await axios.post(`https://api-rnf.seguronline.es/expedientnote`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

			if(file) {
				axios.post(`https://api-rnf.seguronline.es/expedientnote/file/${data.id}`, file, {
					headers: {
						Authorization: `${cookies.token}`
					}
				}).then(({data}) => {

					window.location.reload();

				}).catch(({response: {data}}) => {
					alert("Error al subir el documento");
				});
			}else{

				fetchExpedientnotes(0);

				setDate(today);
				setNote("");

			}

		}).catch(({response:{data}})=>{
			alert("Error en los datos");
		});
	};

	const handleDownload  = async (filename)  =>  {

		await axios({
			url: `https://api-rnf.seguronline.es/expedientnote/download/${id}/${filename}`,
			method: 'GET',
			responseType: 'blob', // important
			headers: {Authorization: `${cookies.token}`}
		}).then(({data})=>{
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la descarga del documento");
		});
	};


	return (
		<Container>
			<br/>
			<h2>Editar siniestro</h2>
			<br/>
			<Form onSubmit={editExpedient}>
				<Row>
					<Col>
						<Form.Group controlId="expedient_number">
							<Form.Label>Nº expediente:</Form.Label>
							<Form.Control disabled={true} type="text" value={expedient_number} onChange={(event) => {
								setExpedientNumber(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="created_at">
							<Form.Label>Fecha de comunicación:</Form.Label>
							<Form.Control disabled={true} type="date" value={created_at} onChange={(event) => {
								setCreatedAt(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="assignmentDate">
							<Form.Label>Fecha de ocurrencia:</Form.Label>
							<Form.Control disabled={true} type="date" value={expedient_date} onChange={(event) => {
								setExpedientDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="year">
							<Form.Label>Anualidad de imputación:</Form.Label>
							<Form.Control disabled={cookies.user != 'mya'} type="number" value={year}
										  onChange={(event) => {
											  setYear(event.target.value);
										  }}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="year">
							<Form.Label>Nº persona:</Form.Label>
							<Form.Control disabled={true} type="text" value={person_number_id}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="name">
							<Form.Label>Nombre:</Form.Label>
							<Form.Control disabled={true} type="text" value={certificate ? certificate.name : ''}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="year">
							<Form.Label>NIF:</Form.Label>
							<Form.Control disabled={true} type="text" value={certificate ? certificate.nif : ''}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="year">
							<Form.Label>Fecha nacimiento:</Form.Label>
							<Form.Control disabled={true} type="date" value={certificate ? certificate.birthdate : ''}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="situation">
							<Form.Label>Contingencia:</Form.Label>
							<Form.Select disabled={true} value={contingency} onChange={(event) => {
								setContingency(event.target.value);
							}}>
								<option value="">Selecciona...</option>
								<option value="1">Fallecimiento</option>
								<option value="2">Incapacidad Permanente Absoluta para todo trabajo y/o gran invalidez
								</option>
								<option value="3">Incapacidad permanente total para su profesión habitual</option>
								<option value="4">Incapacidad permanente parcial</option>
								<option value="5">Riesgos extraordinarios No Consorciables</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Estado:</Form.Label>
							<Form.Select disabled={cookies.user != 'mya'} value={state} onChange={(event) => {
								setState(event.target.value);
							}}>
								<option value="open">Abierto</option>
								<option value="closed">Cerrado</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="tomador">
							<Form.Label>Tomador:</Form.Label>
							<Form.Control disabled={true} type="text" value={name_comp}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="capital">
							<Form.Label>Capital:</Form.Label>
							<Form.Control disabled={true} type="text" value={capital}/>
						</Form.Group>
					</Col>
				</Row>
				{(state === 'closed' ?
					<>
						<br/>
						<Row>
							<Col>
								<Form.Group controlId="assignmentDate">
									<Form.Label>Fecha de cierre:</Form.Label>
									<Form.Control disabled={cookies.user != 'mya'} type="date" value={closed_date}
												  onChange={(event) => {
													  setClosedDate(event.target.value);
												  }}/>
								</Form.Group>
							</Col>
							<Col>
							</Col>
							<Col>
							</Col>
							<Col>
							</Col>
						</Row>
					</>
					: '')}
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="description">
							<Form.Label>Descripción del siniestro:</Form.Label>
							<Form.Control as="textarea" rows="5" disabled={true} value={description}
										  onChange={(event) => {
											  setDescription(event.target.value);
										  }}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="success" className="mt-2" size="md" block="block" type="submit">
					Guardar
				</Button>
			</Form>
			<br/>
			<br/>
			<br/>
			<h3>Documentación del expediente</h3>
			<br/>
			<Table striped hover responsive
				   variant="light">
				<thead>
				<tr>
					<th className={"w-25"}>Fecha</th>
					<th>Documento</th>
				</tr>
				</thead>
				<tbody>
				{expedientnotes ? expedientnotes.map((expedientnote, index) => (
					expedientnote.filename ?
						<tr key={index}>
							<td>{expedientnote.date}</td>
							<td><span style={{cursor: 'pointer', textDecoration: 'underline'}}
									  onClick={() => handleDownload(expedientnote.filename)}>{expedientnote.filename}</span>
							</td>
						</tr>
						: ''
				)) : <tr>
					<td colSpan={12}>Cargando</td>
				</tr>}
				</tbody>
			</Table>
			<br/>
			<h3>Notas del expediente</h3>
			<br/>
			<Table striped hover responsive
				   variant="light">
				<thead>
				<tr>
					<th className={"w-25"}>Fecha</th>
					<th>Nota</th>
				</tr>
				</thead>
				<tbody>
				{expedientnotes ? expedientnotes.map((expedientnote, index) => (
					expedientnote.note ?
						<tr key={index}>
							<td>{expedientnote.date}</td>
							<td>{expedientnote.note}</td>
						</tr>
						: ''
				)) : <tr>
					<td colSpan={12}>Cargando</td>
				</tr>}
				</tbody>
			</Table>
			<br/>

			<br/>
			<h4>Añadir documentos</h4>
			<br/>
			<Form onSubmit={expedientDocs}>
				<Row>
					<Col>
						<Form.Group controlId="assignmentDate">
							<Form.Label>Fecha:</Form.Label>
							<Form.Control type="date" value={date} onChange={(event) => {
								setDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="documento">
							<Form.Label>Subir documento (máx. 30MB):</Form.Label>
							<Form.Control type="file" onChange={(event) => {
								setFile(event.target.files[0]);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="success" className="mt-2" size="md" block="block" type="submit">
					Añadir Documento
				</Button>
			</Form>
			<br/>
			<br/>
			<h4>Añadir notas</h4>
			<br/>
			<Form onSubmit={expedientNotes}>
				<Row>
					<Col>
						<Form.Group className="mb-3" controlId="nota">
							<Form.Label>Nota:</Form.Label>
							<Form.Control as="textarea" rows={3} value={note} onChange={(event) => {
								setNote(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="success" className="mt-2" size="md" block="block" type="submit">
					Añadir Nota
				</Button>
			</Form>
		</Container>
	);
}