import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function ExpedientCreate() {

	const [ description, setDescription ] = useState("");
	const [ expedient_date, setExpedientDate ] = useState("");
	const [ person_number_id, setPersonNumberId ] = useState("");
	const [ contingency, setContingency ] = useState("");

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
	},[]);


	const createExpedient = async (e) => {
		e.preventDefault();

		const raw = {
			'description': description,
			'expedient_date': expedient_date,
			'year': expedient_date.substring(0,4),
			'person_number_id': person_number_id,
			'contingency': contingency,
		};

		await axios.post(`https://api-rnf.seguronline.es/expedient`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			navigate(`/expedient/${data.id}`)
		}).catch(({response:{data}})=>{
			alert("Error al aperturar el siniestro. Datos incorrectos");
		});
	};

	return (
		<Container>
			<br />
			<h2>Aperturar siniestro</h2>
			<br />
			<Form onSubmit={createExpedient}>
				<Row>
					<Col>
						<Form.Group controlId="assignmentDate">
							<Form.Label>Fecha de ocurrencia:</Form.Label>
							<Form.Control type="date" min="2024-01-01" value={expedient_date} onChange={(event) => {
								setExpedientDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="year">
							<Form.Label>Nº persona:</Form.Label>
							<Form.Control type="person_number_id" value={person_number_id} onChange={(event) => {
								setPersonNumberId(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="situation">
							<Form.Label>Contingencia:</Form.Label>
							<Form.Select onChange={(event)=>{
								setContingency(event.target.value);
							}}>
								<option value="">Selecciona...</option>
								<option value="1">Fallecimiento</option>
								<option value="2">Incapacidad Permanente Absoluta para todo trabajo y/o gran invalidez</option>
								<option value="3">Incapacidad permanente total para su profesión habitual</option>
								<option value="4">Incapacidad permanente parcial</option>
								<option value="5">Riesgos extraordinarios No Consorciables</option>
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="description">
							<Form.Label>Descripción del siniestro:</Form.Label>
							<Form.Control as="textarea" rows="5" value={description} onChange={(event)=>{
								setDescription(event.target.value);
							}}  />
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="danger" className="mt-2" size="md" block="block" type="submit">
					Aperturar siniestro
				</Button>
			</Form>
		</Container>
	);
}