import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import Form from "react-bootstrap/Form";

export default function CertificateIndex() {

	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [loading, setLoading] = useState("");
	const [ collectives, setCollectives ] = useState(null);
	const [ contingency, setContingency ] = useState("");
	const [ ini_date, setIniDate ] = useState("2024-01-01");
	const [ end_date, setEndDate ] = useState("2024-12-31");
	const [ totals, setTotals ] = useState(true);


	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
		fetchCollective();
	},[]);

	const fetchCollective = async () => {
		await axios.get('https://api-rnf.seguronline.es/collective',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCollectives(data);
		});
	};

	const fetchReport = async () => {

	};

	const fetchExcel = async () => {

	};


	return (
		<Container>
			<br/>
			<h1>Informes de siniestralidad</h1>
			<br/>
			<br/>
			<Container>
				<h2>Totales</h2>
				<Row>
					<Col>
						<Form.Group controlId="iniDate">
							<Form.Label>Fecha de inicio:</Form.Label>
							<Form.Control type="date" min="2024-01-01" value={ini_date} onChange={(event) => {
								setIniDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="endDate">
							<Form.Label>Fecha de fin:</Form.Label>
							<Form.Control type="date" min="2024-12-31" value={end_date} onChange={(event) => {
								setEndDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="collective_id">
							<Form.Group controlId="formExpert">
								<Form.Label>Sociedad:</Form.Label>
								<Form.Select>
									<option value="">Todas...</option>
									{collectives ? collectives.map((collective, index) => (
										<option value={collective.id}>{collective.name}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="situation">
							<Form.Label>Contingencia:</Form.Label>
							<Form.Select onChange={(event)=>{
								setContingency(event.target.value);
							}}>
								<option value="">Todas</option>
								<option value="1">Fallecimiento</option>
								<option value="2">Incapacidad Permanente Absoluta para todo trabajo y/o gran invalidez</option>
								<option value="3">Incapacidad permanente total para su profesión habitual</option>
								<option value="4">Incapacidad permanente parcial</option>
								<option value="5">Riesgos extraordinarios No Consorciables</option>
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>
				<br />
				<Row>
					<Col>
						<Form.Check
							type="switch"
							id="custom-switch"
							label="Sólo totales"
							checked={totals} onChange={(event) => {
							setTotals(event.target.checked);
						}}
						/>
					</Col>
				</Row>
				<br />
				{loading ? <Spinner animation="border" variant="success"/> :
					<Button variant="success" onClick={fetchReport}>Ver resultados</Button>}
				&nbsp; &nbsp;
				{loading ? <Spinner animation="border" variant="success"/> :
					<Button variant="secondary" onClick={fetchExcel}>Descargar Excel</Button>}
			</Container>
			<br/>
			<br/>
			<br/>
			<br/>
		</Container>
	);
}