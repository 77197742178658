import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function CertificateEdit() {
	const {id} = useParams();
	const [ person_number, setPersonNumber ] = useState("");
	const [ name, setName ] = useState("");
	const [ nif, setNif ] = useState("");
	const [ gender, setGender ] = useState(1);
	const [ birthdate, setBirthdate ] = useState("");
	const [ divp, setDivp ] = useState("");
	const [ sdvper, setSdvper ] = useState("");
	const [ subdivision, setSubdivision ] = useState("");
	const [ subgroup, setSubgroup ] = useState("");
	const [ movement_date, setMovementDate ] = useState("");
	const [ collective_id, setCollectiveId ] = useState("");
	const [ effectDate, setEffectDate ] = useState("");
	const [ finishDate, setFinishDate ] = useState("");
	const [ certificatePrintDate, setCertificatePrintDate ] = useState("");
	const [ down, setDown ] = useState("");
	const [ downDate, setDownDate ] = useState("");

	const [ beneficiary1, setBeneficiariy1 ] = useState("1) Cónyuge no separado legalmente o la pareja de hecho e Hijos por partes iguales.");
	const [ beneficiary2, setBeneficiariy2 ] = useState("2) Cónyuge no separado legalmente o la pareja de hecho.");
	const [ beneficiary3, setBeneficiariy3 ] = useState("3) Hijos por partes iguales.");
	const [ beneficiary4, setBeneficiariy4 ] = useState("4) Padres por partes iguales.");
	const [ beneficiary5, setBeneficiariy5 ] = useState("5) Hermanos por partes iguales.");
	const [ beneficiary6, setBeneficiariy6 ] = useState("6) Herederos Legales.");

	const [ collectives, setCollectives ] = useState(null);
	const [ capitals, setCapitals ] = useState(null);

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
		fetchCollective();
		fetchCapital();
		fetchCertificate();
	},[]);

	const fetchCollective = async () => {
		await axios.get('https://api-rnf.seguronline.es/collective',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCollectives(data);
		});
	};

	const fetchCapital = async () => {
		await axios.get('https://api-rnf.seguronline.es/capital',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCapitals(data);
		});
	};

	const fetchCertificate = async () => {
		await axios.get(`https://api-rnf.seguronline.es/certificate/find/${id}`,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setPersonNumber(data[0].person_number);
			setName(data[0].name);
			setNif(data[0].nif);
			setGender(data[0].gender);
			setBirthdate(data[0].birthdate);
			setDivp(data[0].divp);
			setSdvper(data[0].sdvper);
			setSubdivision(data[0].subdivision);
			setSubgroup(data[0].subgroup);
			setMovementDate(data[0].movement_date);
			setCollectiveId(data[0].collective_id);
			setEffectDate(data[0].effect_date);
			setFinishDate(data[0].finish_date);
			setCertificatePrintDate(data[0].certificate_print_date);
			setDown(data[0].down);
			setDownDate(data[0].down_date);

			if(data[0].beneficiaries) {

				setBeneficiariy1("");
				setBeneficiariy2("");
				setBeneficiariy3("");
				setBeneficiariy4("");
				setBeneficiariy5("");
				setBeneficiariy6("");

				if(data[0].beneficiaries.benefic_a1) {
					setBeneficiariy1('1) ' + data[0].beneficiaries.benefic_a1 + '. NIF: ' + data[0].beneficiaries.dni_a1);
				}
				if(data[0].beneficiaries.benefic_a2) {
					setBeneficiariy2('2) ' + data[0].beneficiaries.benefic_a2 + '. NIF: ' + data[0].beneficiaries.dni_a2);
				}
				if(data[0].beneficiaries.benefic_a3) {
					setBeneficiariy3('3) ' + data[0].beneficiaries.benefic_a3 + '. NIF: ' + data[0].beneficiaries.dni_a3);
				}
				if(data[0].beneficiaries.benefic_a4) {
					setBeneficiariy4('4) ' + data[0].beneficiaries.benefic_a4 + '. NIF: ' + data[0].beneficiaries.dni_a4);
				}
				if(data[0].beneficiaries.benefic_a5) {
					setBeneficiariy5('5) ' + data[0].beneficiaries.benefic_a5 + '. NIF: ' + data[0].beneficiaries.dni_a5);
				}
				if(data[0].beneficiaries.benefic_a6) {
					setBeneficiariy6('6) ' + data[0].beneficiaries.benefic_a6 + '. NIF: ' + data[0].beneficiaries.dni_a6);
				}
			}
		});
	};

	const navigateToBeneficiary = async (e) => {
		navigate("/beneficiary/"+id);
	}
	const navigateToDown = async (e) => {
		navigate("/certificate/down/"+id);
	}

	const updateCertificate = async (e) => {
		e.preventDefault();

		const raw = {
			'person_number': person_number,
			'name': name,
			'nif': nif,
			'gender': gender,
			'birthdate': birthdate,
			'divp': divp,
			'sdvper': sdvper,
			'subdivision': subdivision,
			'subgroup': subgroup,
			'movement_date': movement_date,
			'collective_id': collective_id,
			'effect_date': effectDate,
			'finish_date': finishDate,
			'certificate_print_date': certificatePrintDate
		};

		await axios.patch(`https://api-rnf.seguronline.es/certificate/${id}`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			navigate(`/`)
		}).catch(({response:{data}})=>{
			alert("Error al editar el certificado. Datos incorrectos");
		});
	};

	return (
		<Container>
			<br />
			<h2>Editar certificado</h2>
			<br />
			<Form onSubmit={updateCertificate}>
				{down ?
					<Row>
						<Col>
							<Form.Group controlId="downDate">
								<Form.Label>Fecha de baja:</Form.Label>
								<Form.Control disabled={down} type="date" value={downDate} onChange={(event) => {
									setBirthdate(event.target.value);
								}}/>
							</Form.Group>
						</Col>
						<Col>
						</Col>
					</Row> : ""
				}
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="person_number">
							<Form.Label>Nº persona:</Form.Label>
							<Form.Control disabled={down} type="text" value={person_number} onChange={(event) => {
								setPersonNumber(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="name">
							<Form.Label>Nombre:</Form.Label>
							<Form.Control disabled={down} type="text" value={name} onChange={(event) => {
								setName(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="nif">
							<Form.Label>NIF:</Form.Label>
							<Form.Control disabled={down} type="text" value={nif} onChange={(event) => {
								setNif(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="gender">
							<Form.Label>Sexo:</Form.Label>
							<Form.Control disabled={down} value={gender} as="select" onChange={(event) => {
								setGender(event.target.value);
							}}>
								<option value="1">Hombre</option>
								<option value="2">Mujer</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="assignmentDate">
							<Form.Label>Fecha de nacimiento:</Form.Label>
							<Form.Control disabled={down} type="date" value={birthdate} onChange={(event) => {
								setBirthdate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="sdvper">
							<Form.Label>SDvPer.:</Form.Label>
							<Form.Control disabled={down} type="text" value={sdvper} onChange={(event) => {
								setSdvper(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="subdivision">
							<Form.Label>Subdivisión de personal:</Form.Label>
							<Form.Control disabled={down} type="text" value={subdivision} onChange={(event) => {
								setSubdivision(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="divp">
							<Form.Label>DivP.:</Form.Label>
							<Form.Control disabled={down} type="text" value={divp} onChange={(event) => {
								setDivp(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="name">
							<Form.Group controlId="subgroup">
								<Form.Label>Subgrupo:</Form.Label>
								<Form.Select disabled={down} value={subgroup} onChange={(event) => {
									setSubgroup(event.target.value);
								}}>
									<option value="">Selecciona...</option>
									{capitals ? capitals.map((capital, index) => (
										<option value={capital.id}>{capital.id}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="movement_date">
							<Form.Label>Fecha de movimiento:</Form.Label>
							<Form.Control disabled={down} type="date" value={movement_date} onChange={(event) => {
								setMovementDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="collective_id">
							<Form.Group controlId="collective_id">
								<Form.Label>Sociedad:</Form.Label>
								<Form.Select disabled={down} value={collective_id} onChange={(event) => {
									setCollectiveId(event.target.value);
								}}>
									<option value="">Selecciona...</option>
									{collectives ? collectives.map((collective, index) => (
										<option value={collective.id}>{collective.name}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="certificatePrintDate">
							<Form.Label>Fecha de impresión del certificado:</Form.Label>
							<Form.Control disabled={down} type="date" value={certificatePrintDate}
										  onChange={(event) => {
											  setCertificatePrintDate(event.target.value);
										  }}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="effectDate">
							<Form.Label>Fecha de efecto del certificado:</Form.Label>
							<Form.Control disabled={down} type="date" value={effectDate} onChange={(event) => {
								setEffectDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="finishDate">
							<Form.Label>Fecha fin del certificado:</Form.Label>
							<Form.Control disabled={down} type="date" value={finishDate} onChange={(event) => {
								setFinishDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						Beneficiarios:<br/><br/>
						{beneficiary1} <br/>
						{beneficiary2} <br/>
						{beneficiary3} <br/>
						{beneficiary4} <br/>
						{beneficiary5} <br/>
						{beneficiary6} <br/>
					</Col>
				</Row>
				<br/>
				{down ? "" :
					<Container>
						<Button variant="success" className="mt-2" size="md" block="block" type="submit">
							Guardar
						</Button> &nbsp; &nbsp;
						<Button variant="secondary" className="mt-2" size="md" block="block" type="button"
								onClick={navigateToBeneficiary}>
							Editar / Añadir Beneficiarios
						</Button> &nbsp; &nbsp;
						<Button variant="danger" className="mt-2" size="md" block="block" type="button"
								onClick={navigateToDown}>
							Ir a bajas
						</Button>
					</Container>}
			</Form>
		</Container>
	);
}