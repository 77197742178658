import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function BeneficiaryEdit() {
	const {id} = useParams();

	const [ person_number, setPersonNumber ] = useState("");
	const [ name, setName ] = useState("");
	const [ nif, setNif ] = useState("");

	const [ beneficiary1, setBeneficiariy1 ] = useState("");
	const [ beneficiary2, setBeneficiariy2 ] = useState("");
	const [ beneficiary3, setBeneficiariy3 ] = useState("");
	const [ beneficiary4, setBeneficiariy4 ] = useState("");
	const [ beneficiary5, setBeneficiariy5 ] = useState("");
	const [ beneficiary6, setBeneficiariy6 ] = useState("");

	const [ dni1, setDni1 ] = useState("");
	const [ dni2, setDni2 ] = useState("");
	const [ dni3, setDni3 ] = useState("");
	const [ dni4, setDni4 ] = useState("");
	const [ dni5, setDni5 ] = useState("");
	const [ dni6, setDni6 ] = useState("");

	const [ exists, setExists ] = useState(false);

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
		fetchCertificate();
	},[]);


	const fetchCertificate = async () => {
		await axios.get(`https://api-rnf.seguronline.es/certificate/find/${id}`,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

			setPersonNumber(data[0].person_number);
			setName(data[0].name);
			setNif(data[0].nif);

			if(data[0].beneficiaries) {

				setExists(true);

				setBeneficiariy1(data[0].beneficiaries.benefic_a1);
				setDni1(data[0].beneficiaries.dni_a1);

				setBeneficiariy2(data[0].beneficiaries.benefic_a2);
				setDni2(data[0].beneficiaries.dni_a2);

				setBeneficiariy3(data[0].beneficiaries.benefic_a3);
				setDni3(data[0].beneficiaries.dni_a3);

				setBeneficiariy4(data[0].beneficiaries.benefic_a4);
				setDni4(data[0].beneficiaries.dni_a4);

				setBeneficiariy5(data[0].beneficiaries.benefic_a5);
				setDni5(data[0].beneficiaries.dni_a5);

				setBeneficiariy6(data[0].beneficiaries.benefic_a6);
				setDni6(data[0].beneficiaries.dni_a6);

			}
		});
	};

	const updateBeneficieries = async (e) => {
		e.preventDefault();
		if(beneficiary1.length > 1) {
			if (exists) {
				const raw = {
					'person_number_id': person_number,
					'benefic_a1': beneficiary1,
					'dni_a1': dni1,
					'benefic_a2': beneficiary2,
					'dni_a2': dni2,
					'benefic_a3': beneficiary3,
					'dni_a3': dni3,
					'benefic_a4': beneficiary4,
					'dni_a4': dni4,
					'benefic_a5': beneficiary5,
					'dni_a5': dni5,
					'benefic_a6': beneficiary6,
					'dni_a6': dni6
				};

				await axios.patch(`https://api-rnf.seguronline.es/beneficiary/${person_number}`, raw, {
					headers: {
						Authorization: `${cookies.token}`
					}
				}).then(({}) => {
					navigate(`/certificate/${id}`);
				}).catch(({response: {data}}) => {
					alert("Error al crear los beneficiarios. Datos incorrectos");
				});

			} else {
				const raw = {
					'person_number_id': person_number,
					'benefic_a1': beneficiary1,
					'dni_a1': dni1,
					'benefic_a2': beneficiary2,
					'dni_a2': dni2,
					'benefic_a3': beneficiary3,
					'dni_a3': dni3,
					'benefic_a4': beneficiary4,
					'dni_a4': dni4,
					'benefic_a5': beneficiary5,
					'dni_a5': dni5,
					'benefic_a6': beneficiary6,
					'dni_a6': dni6
				};

				await axios.post(`https://api-rnf.seguronline.es/beneficiary`, raw, {
					headers: {
						Authorization: `${cookies.token}`
					}
				}).then(({}) => {
					navigate(`/certificate/${id}`);
				}).catch(({response: {data}}) => {
					alert("Error al crear los beneficiarios. Datos incorrectos");
				});
			}
		}else{
			alert("Rellene al menos un beneficiario");
		}
	};

	const deleteBeneficiaries = async (e) => {
		e.preventDefault();

		const answer = window.confirm("¿Seguro que quiere borrar los beneficiarios? (Una vez borrados, aparecerán los valores por defecto)");

		if(answer) {
			await axios.delete(`https://api-rnf.seguronline.es/beneficiary/${person_number}`, {
				headers: {
					Authorization: `${cookies.token}`
				}
			}).then(() => {
				navigate(`/certificate/${id}`);
			}).catch(({response: {data}}) => {
				alert("Error al borrar los beneficiarios. Contacte con el administrador");
			});
		}
	};

	return (
		<Container>
			<br />
			<h2>Añadir/Editar Beneficiarios</h2>
			<br />
			<Form onSubmit={updateBeneficieries}>
				<Row>
					<Col>
						<Form.Group controlId="person_number">
							<Form.Label>Nº persona:</Form.Label>
							<Form.Control disabled={true} type="text" value={person_number} onChange={(event) => {
								setPersonNumber(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="name">
							<Form.Label>Nombre:</Form.Label>
							<Form.Control disabled={true} type="text" value={name} onChange={(event) => {
								setName(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="nif">
							<Form.Label>NIF:</Form.Label>
							<Form.Control disabled={true} type="text" value={nif} onChange={(event) => {
								setNif(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/><br/>
				<h3>Beneficiarios:</h3>
				Si esta vacío se cogen los beneficiarios por defecto del certificado.
				<br/><br/>
				<Row>
					<Col>
						<Form.Group controlId="beneficiary1">
							<Form.Label>Beneficiario 1:</Form.Label>
							<Form.Control type="text" value={beneficiary1} onChange={(event) => {
								setBeneficiariy1(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dni1">
							<Form.Label>NIF beneficiario 1:</Form.Label>
							<Form.Control type="text" value={dni1} onChange={(event) => {
								setDni1(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="beneficiary2">
							<Form.Label>Beneficiario 2:</Form.Label>
							<Form.Control type="text" value={beneficiary2} onChange={(event) => {
								setBeneficiariy2(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dni2">
							<Form.Label>NIF beneficiario 2:</Form.Label>
							<Form.Control type="text" value={dni2} onChange={(event) => {
								setDni2(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="beneficiary3">
							<Form.Label>Beneficiario 3:</Form.Label>
							<Form.Control type="text" value={beneficiary3} onChange={(event) => {
								setBeneficiariy3(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dni3">
							<Form.Label>NIF beneficiario 3:</Form.Label>
							<Form.Control type="text" value={dni3} onChange={(event) => {
								setDni3(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="beneficiary4">
							<Form.Label>Beneficiario 4:</Form.Label>
							<Form.Control type="text" value={beneficiary4} onChange={(event) => {
								setBeneficiariy4(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dni4">
							<Form.Label>NIF beneficiario 4:</Form.Label>
							<Form.Control type="text" value={dni4} onChange={(event) => {
								setDni4(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="beneficiary5">
							<Form.Label>Beneficiario 5:</Form.Label>
							<Form.Control type="text" value={beneficiary5} onChange={(event) => {
								setBeneficiariy5(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dni5">
							<Form.Label>NIF beneficiario 5:</Form.Label>
							<Form.Control type="text" value={dni5} onChange={(event) => {
								setDni5(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="beneficiary6">
							<Form.Label>Beneficiario 6:</Form.Label>
							<Form.Control type="text" value={beneficiary6} onChange={(event) => {
								setBeneficiariy6(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dni6">
							<Form.Label>NIF beneficiario 6:</Form.Label>
							<Form.Control type="text" value={dni6} onChange={(event) => {
								setDni6(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="success" className="mt-2" size="md" block="block" type="submit">
					Guardar beneficiaros
				</Button>
				&nbsp; &nbsp;
				{exists ? <Button variant="danger" className="mt-2" size="md" block="block" type="button" onClick={deleteBeneficiaries}>
					Eliminar todos los beneficiarios
				</Button> : '' }
			</Form>
		</Container>
	);
}