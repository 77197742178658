import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function CertificateCreate() {

	const [ person_number, setPersonNumber ] = useState("");
	const [ name, setName ] = useState("");
	const [ nif, setNif ] = useState("");
	const [ gender, setGender ] = useState(1);
	const [ birthdate, setBirthdate ] = useState("");
	const [ divp, setDivp ] = useState("");
	const [ sdvper, setSdvper ] = useState("");
	const [ subdivision, setSubdivision ] = useState("");
	const [ subgroup, setSubgroup ] = useState("");
	const [ movement_date, setMovementDate ] = useState("");
	const [ collective_id, setCollectiveId ] = useState("");
	const [ effectDate, setEffectDate ] = useState("2024-01-01");
	const [ finishDate, setFinishDate ] = useState("2025-01-01");
	const [ certificatePrintDate, setCertificatePrintDate ] = useState("2024-02-01");

	const [ collectives, setCollectives ] = useState(null);
	const [ capitals, setCapitals ] = useState(null);

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
		fetchCollective();
		fetchCapital();
	},[]);

	const fetchCollective = async () => {
		await axios.get('https://api-rnf.seguronline.es/collective',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCollectives(data);
		});
	};

	const fetchCapital = async () => {
		await axios.get('https://api-rnf.seguronline.es/capital',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCapitals(data);
		});
	};

	const createCertificate = async (e) => {
		e.preventDefault();

		const raw = {
			'person_number': person_number,
			'name': name,
			'nif': nif,
			'gender': gender,
			'birthdate': birthdate,
			'divp': divp,
			'sdvper': sdvper,
			'subdivision': subdivision,
			'subgroup': subgroup,
			'movement_date': movement_date,
			'collective_id': collective_id,
			'effect_date': effectDate,
			'finish_date': finishDate,
			'certificate_print_date': certificatePrintDate
		};

		await axios.post(`https://api-rnf.seguronline.es/certificate`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			navigate(`/`)
		}).catch(({response:{data}})=>{
			alert("Error al crear el certificado. Datos incorrectos");
		});
	};

	const handleMassive  = async (e) => {
		navigate("/certificate/import");
	};

	return (
		<Container>
			<br/>
			<Row>
				<Col>
					<h2>Crear certificado</h2>
				</Col>
				<Col>
					<Button style={{float:"right"}} className="text-center" variant="secondary" onClick={handleMassive}>Ir a carga masiva de datos</Button>
				</Col>
			</Row>
			<br/>
			<Form onSubmit={createCertificate}>
				<Row>
					<Col>
						<Form.Group controlId="person_number">
							<Form.Label>Nº persona:</Form.Label>
							<Form.Control type="text" value={person_number} onChange={(event) => {
								setPersonNumber(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="name">
							<Form.Label>Nombre:</Form.Label>
							<Form.Control type="text" value={name} onChange={(event) => {
								setName(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="nif">
							<Form.Label>NIF:</Form.Label>
							<Form.Control type="text" value={nif} onChange={(event) => {
								setNif(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="gender">
							<Form.Label>Sexo:</Form.Label>
							<Form.Control as="select" onChange={(event) => {
								setGender(event.target.value);
							}}>
								<option value="1" selected="selected">Hombre</option>
								<option value="2">Mujer</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="assignmentDate">
							<Form.Label>Fecha de nacimiento:</Form.Label>
							<Form.Control type="date" value={birthdate} onChange={(event) => {
								setBirthdate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="sdvper">
							<Form.Label>SDvPer.:</Form.Label>
							<Form.Control type="text" value={sdvper} onChange={(event) => {
								setSdvper(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="subdivision">
							<Form.Label>Subdivisión de personal:</Form.Label>
							<Form.Control type="text" value={subdivision} onChange={(event) => {
								setSubdivision(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="divp">
							<Form.Label>DivP.:</Form.Label>
							<Form.Control type="text" value={divp} onChange={(event) => {
								setDivp(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="name">
							<Form.Group controlId="formExpert">
								<Form.Label>Subgrupo:</Form.Label>
								<Form.Select onChange={(event) => {
									setSubgroup(event.target.value);
								}}>
									<option value="">Selecciona...</option>
									{capitals ? capitals.map((capital, index) => (
										<option value={capital.id}>{capital.id}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="movement_date">
							<Form.Label>Fecha de incorporación:</Form.Label>
							<Form.Control type="date" value={movement_date} onChange={(event) => {
								setMovementDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="collective_id">
							<Form.Group controlId="formExpert">
								<Form.Label>Sociedad:</Form.Label>
								<Form.Select onChange={(event) => {
									setCollectiveId(event.target.value);
								}}>
									<option value="">Selecciona...</option>
									{collectives ? collectives.map((collective, index) => (
										<option value={collective.id}>{collective.name}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="certificatePrintDate">
							<Form.Label>Fecha de impresión del certificado:</Form.Label>
							<Form.Control type="date" value={certificatePrintDate} onChange={(event) => {
								setCertificatePrintDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="effectDate">
							<Form.Label>Fecha de efecto del certificado:</Form.Label>
							<Form.Control type="date" value={effectDate} onChange={(event) => {
								setEffectDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="finishDate">
							<Form.Label>Fecha fin del certificado:</Form.Label>
							<Form.Control type="date" value={finishDate} onChange={(event) => {
								setFinishDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="success" className="mt-2" size="md" block="block" type="submit">
					Crear certificado
				</Button>
			</Form>
		</Container>
	);
}