import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
import Spinner from 'react-bootstrap/Spinner';

export default function CertificateIndex() {

	const [ certificates, setCertificates ] = useState(null);
	const [ totalItems, setTotalItems ] = useState(0);
	const itemsPerPage = 30;
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [searchValue, setSearch] = useState("");
	const [stateValue, setStateValue] = useState("");
	const [societyValue, setSocietyValue] = useState("");
	const [loading, setLoading] = useState("");
	const [ collectives, setCollectives ] = useState(null);

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}else{
			fetchCertificates(0);
			fetchCollective();
		}
	},[]);

	const handleSearch = (event) => {
		event.preventDefault();

		let search =  event.target.value;

		setSearch(search);
		fetchCertificates(0,search,stateValue,societyValue);

	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % totalItems;
		if(searchValue.length >= 3){
			fetchCertificates(newOffset,searchValue);
		}else{
			fetchCertificates(newOffset);
		}

	};

	const fetchCollective = async () => {
		await axios.get('https://api-rnf.seguronline.es/collective',{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
			setCollectives(data);
		});
	};

	const fetchCertificates = async (offset,search,state,society) => {
		if(search && search.length >= 3 || state && state.length >= 1 || society && society.length >= 1 ) {
			await axios.post("https://api-rnf.seguronline.es/certificate/filter/" + offset, {
				"search": search,
				"state": state,
				"society": society
			},{
				headers: {
					Authorization: `${cookies.token}`
				}
			}).then(({data}) => {
				setCertificates(data.certificate);
				setTotalItems(data.totalItems);
			});
		}else{
			await axios.get("https://api-rnf.seguronline.es/certificate/paginate/" + offset, {
				headers: {Authorization: `${cookies.token}`}
			}).then(({data}) => {
				setCertificates(data.certificate);
				setTotalItems(data.totalItems);
			});
		}

	};

	const handleState = (event) => {
		event.preventDefault();

		let state =  event.target.value;

		setStateValue(state);
		fetchCertificates(0,searchValue,state,societyValue);

	};

	const handleSociety = (event) => {
		event.preventDefault();

		let society =  event.target.value;

		setSocietyValue(society);
		fetchCertificates(0,searchValue,stateValue,society);

	};

	const pageCount = Math.ceil(totalItems / itemsPerPage);

	const handlePrintPdf = async (event) => {
		await axios({
			url: 'https://api-rnf.seguronline.es/certificate/pdf/'+event.target.value,
			method: 'GET',
			responseType: 'blob', // important
			headers: {Authorization: `${cookies.token}`}
		}).then(({data})=>{
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			let name = 'expediente';
			if(event.target.dataset.ref){
				name = event.target.dataset.ref;
			}
			link.href = url;
			link.setAttribute('download', name+'.pdf');
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la generación del PDF");
		});

	};

	const handlePrintAllPdf = async (event) => {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};



	return (
		<Container>
			<br/>
			<h1>Lista de Certificados</h1>
			<br/>
			<Container>
				<Row>
					<Col>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Buscar:</Form.Label>
							<input placeholder="Búsqueda rápida" className="form-control" type="search" name="search"
								   value={searchValue || ""} onChange={handleSearch} onKeyUp={(e) => {
								if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
									setTimeout(() => {
										handleSearch(e)
									}, 750);
								}
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Estado:</Form.Label>
							<Form.Select onChange={handleState} >
								<option value="">Todos...</option>
								<option value="1">Dados de alta</option>
								<option value="2">Dados de baja</option>
								<option value="3">Modificados</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="collective_id">
							<Form.Group controlId="formExpert">
								<Form.Label>Sociedad:</Form.Label>
								<Form.Select onChange={handleSociety}>
									<option value="">Todas...</option>
									{collectives ? collectives.map((collective, index) => (
										<option value={collective.id}>{collective.name}</option>
									)) : <option value=''>Cargando...</option>}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col style={{paddingTop:'8px'}}>
						{loading ? <Spinner animation="border" variant="success" /> : <Button className="text-center" variant="success" onClick={handlePrintAllPdf}>Descargar todos los
							certificados</Button>}
					</Col>
					<Col style={{paddingTop:'8px'}}>
						<div style={{marginTop: "6px"}}>Total <br />certificados: {totalItems}</div>
					</Col>
				</Row>
			</Container>
			<Container className="text-center">

			</Container>
			<br />
			<Table striped hover responsive
				   variant="light">
				<thead>
				<tr>
					<th>Nº persona</th>
					<th>Nombre</th>
					<th>NIF</th>
					<th>Sociedad</th>
					<th>Acciones</th>
				</tr>
				</thead>
				<tbody>
				{certificates ? certificates.map((certificate, index) => (

					<tr key={index}>
						<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.person_number}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.name}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.nif}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/certificate/"+certificate.id}>{certificate.collectives.name}</Link></td>
						{certificate.down ? <td>Baja, {new Date(certificate.down_date).toLocaleDateString()}</td> : <td><Button data-ref={certificate.person_number} value={certificate.id} size="sm" variant="success" onClick={handlePrintPdf}>Imprimir
							PDF</Button></td>}
					</tr>
				)) : <tr>
					<td colSpan={12}>Cargando</td>
				</tr>}
				</tbody>
			</Table>
			<br/>
			<Container>
				<ReactPaginate
					breakLabel="..."
					nextLabel="siguiente >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={10}
					pageCount={pageCount}
					previousLabel="< anterior"
					marginPagesDisplayed={2}
					pageClassName="page-item text-success-emphasis"
					pageLinkClassName="page-link text-success-emphasis"
					previousClassName="page-item text-success-emphasis"
					previousLinkClassName="page-link text-success-emphasis"
					nextClassName="page-item text-success-emphasis"
					nextLinkClassName="page-link text-success-emphasis"
					breakClassName="page-item text-success-emphasis"
					breakLinkClassName="page-link text-success-emphasis"
					containerClassName="pagination text-success-emphasis"
					activeClassName="active text-success-emphasis"
					renderOnZeroPageCount={null}
				/>
			</Container>


		</Container>


	);
}