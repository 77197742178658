import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';

export default function CertificateIndex() {

	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [loading, setLoading] = useState("");

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
	},[]);

	const handlePrintAllPdf = async (event) => {

		const answer = window.confirm("¿Seguro que quiere descargar todos los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	const handlePrintPdf1000  = async ()  =>  {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf/collective/1000',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados-1000.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	const handlePrintPdf1400  = async ()  =>  {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf/collective/1400',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados-1400.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	const handlePrintPdf1500  = async ()  =>  {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf/collective/1500',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados-1500.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	const handlePrintPdf1600  = async ()  =>  {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf/collective/1600',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados-1600.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	const handlePrintPdf1700  = async ()  =>  {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf/collective/1700',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados-1700.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	const handlePrintPdf1800  = async ()  =>  {

		const answer = window.confirm("¿Seguro que quiere descargar los certificados?");

		if (answer) {
			setLoading(true);
			await axios({
				url: 'https://api-rnf.seguronline.es/certificate/pdf/collective/1800',
				method: 'GET',
				responseType: 'blob', // important
				headers: {Authorization: `${cookies.token}`}
			}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados-1800.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};

	return (
		<Container>
			<br/>
			<h1>Descargar Certificados</h1>
			<br/>
			<br/>
			<Container>
				<Row>
					<Col>
						{loading ? <Spinner animation="border" variant="success"/> :
							<Button className="text-center" variant="success" onClick={handlePrintPdf1000}>Descargar los
								certificados de E.P.E. RENFE OPERADORA</Button>}
					</Col>
					<Col>
						{loading ? '' :
							<Button className="text-center" variant="success" onClick={handlePrintPdf1400}>Descargar los
								certificados de RENFE VIAJEROS</Button>}
					</Col>
					<Col>
						{loading ? '' :
							<Button className="text-center" variant="success" onClick={handlePrintPdf1500}>Descargar los
								certificados de RENFE MERCANCIAS</Button>}
					</Col>
					<Col>
						{loading ? '' :
							<Button style={{width: '100%'}} className="text-center" variant="success" onClick={handlePrintAllPdf}>Descargar todos <br /> los certificados</Button>}
					</Col>

				</Row>
				<Row>
					<Col>
						<br/>
					</Col>
				</Row>
				<Row>
					<Col>
						{loading ? '' :
							<Button className="text-center" variant="success" onClick={handlePrintPdf1600}>Descargar los
								certificados de RENFE FABRICACION Y MANTENIMIENTO</Button>}
					</Col>
					<Col>
						{loading ? '' :
							<Button className="text-center" variant="success" onClick={handlePrintPdf1700}>Descargar los
								certificados de RENFE ALQUILER DE MATERIAL FERROVIARIO</Button>}
					</Col>
					<Col>
						{loading ? '' :
							<Button className="text-center" variant="success" onClick={handlePrintPdf1800}>Descargar los
								certificados de RENFE PROYECTOS INTERNACIONALES</Button>}
					</Col>
					<Col>

					</Col>
				</Row>
			</Container>
			<br/>
			<br/>
			<br/>
			<br/>
		</Container>
	);
}