import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
import Spinner from 'react-bootstrap/Spinner';

export default function ExpedientIndex() {

	const [ expedients, setExpedients ] = useState(null);
	const [ totalItems, setTotalItems ] = useState(0);
	const itemsPerPage = 30;
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [searchValue, setSearch] = useState("");
	const [stateValue, setStateValue] = useState("");

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}else{
			fetchExpedients(0);
		}
	},[]);

	const handleNewExpedient = (event) => {
		event.preventDefault();

		navigate("/expedient/create");

	};

	const handleSearch = (event) => {
		event.preventDefault();

		let search =  event.target.value;

		setSearch(search);
		fetchExpedients(0,search,stateValue);

	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % totalItems;
		if(searchValue.length >= 3){
			fetchExpedients(newOffset,searchValue);
		}else{
			fetchExpedients(newOffset);
		}

	};


	const fetchExpedients = async (offset,search,state) => {
		if(search && search.length >= 3 || state && state.length >= 1) {
			await axios.post("https://api-rnf.seguronline.es/expedient/filter/" + offset, {
				"search": search,
				"state": state
			},{
				headers: {
					Authorization: `${cookies.token}`
				}
			}).then(({data}) => {
				setExpedients(data.expedients);
				setTotalItems(data.totalItems);
			});
		}else{
			await axios.get("https://api-rnf.seguronline.es/expedient/paginate/" + offset, {
				headers: {Authorization: `${cookies.token}`}
			}).then(({data}) => {
				setExpedients(data.expedients);
				setTotalItems(data.totalItems);
			});
		}

	};

	const handleState = (event) => {
		event.preventDefault();

		let state =  event.target.value;

		setStateValue(state);
		fetchExpedients(0,searchValue,state);

	};

	const pageCount = Math.ceil(totalItems / itemsPerPage);


	return (
		<Container>
			<br/>
			<h1>Lista de Siniestros</h1>
			<br/>
			<Container>
				<Row>
					<Col>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Buscar:</Form.Label>
							<input placeholder="Búsqueda rápida" className="form-control" type="search" name="search"
								   value={searchValue || ""} onChange={handleSearch} onKeyUp={(e) => {
								if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
									setTimeout(() => {
										handleSearch(e)
									}, 750);
								}
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Estado:</Form.Label>
							<Form.Select onChange={handleState} >
								<option value="">Todos...</option>
								<option value="open">Abiertos</option>
								<option value="closed">Cerrados</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<br />
						<Button className="text-center" style={{marginTop: '7px'}} variant="danger" onClick={handleNewExpedient}>Aperturar siniestro</Button>
					</Col>
					<Col style={{paddingTop:'8px'}}>
						<div style={{marginTop: "6px"}}>Total <br />siniestros: {totalItems}</div>
					</Col>
				</Row>
			</Container>
			<Container className="text-center">

			</Container>
			<br />
			<Table striped hover responsive
				   variant="light">
				<thead>
				<tr>
					<th>Nº expediente</th>
					<th>Fecha ocurrencia</th>
					<th>Fecha comunicación</th>
					<th>Nº persona</th>
					<th>Nombre</th>
					<th>NIF</th>
					<th>Tomador</th>
					<th>Nº Póliza</th>
					<th>Compañía</th>
					<th>Estado</th>
					<th>Contingencia</th>
					<th>Capital</th>
				</tr>
				</thead>
				<tbody>
				{expedients ? expedients.map((expedient, index) => (
					<tr key={index}>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{expedient.expedient_number}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{expedient.expedient_date}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{expedient.created_at.substring(0,10)}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{expedient.person_number_id}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{(expedient.certificates ? expedient.certificates.name : '')}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{(expedient.certificates ? expedient.certificates.nif : '')}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{expedient.company_name}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{expedient.policy}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>Generali</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{(expedient.state === 'open') ? 'Abierto' : 'Cerrado'}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>{
							expedient.contingency === 1 ? 'Fallecimiento' :
								expedient.contingency === 2 ? 'Incapacidad permanente absoluta' :
								expedient.contingency === 3 ? 'Incapacidad permanente total' :
								expedient.contingency === 4 ? 'Incapacidad permanente parcial' :
								expedient.contingency === 5 ? 'Riesgos extraordinarios' : ''
						}</Link></td>
						<td><Link className="text-primary-emphasis" to={"/expedient/"+expedient.id}>
							{expedient.contingency === 1 ? expedient.death :
							 expedient.contingency === 2 ? expedient.absolute_incapacity :
							 expedient.contingency === 3 ? expedient.total_incapacity :
							 expedient.contingency === 4 ? expedient.partial_incapacity : ''}
						</Link></td>
					</tr>
				)) : <tr>
					<td colSpan={12}>Cargando</td>
				</tr>}
				</tbody>
			</Table>
			<br/>
			<Container>
				<ReactPaginate
					breakLabel="..."
					nextLabel="siguiente >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={10}
					pageCount={pageCount}
					previousLabel="< anterior"
					marginPagesDisplayed={2}
					pageClassName="page-item text-success-emphasis"
					pageLinkClassName="page-link text-success-emphasis"
					previousClassName="page-item text-success-emphasis"
					previousLinkClassName="page-link text-success-emphasis"
					nextClassName="page-item text-success-emphasis"
					nextLinkClassName="page-link text-success-emphasis"
					breakClassName="page-item text-success-emphasis"
					breakLinkClassName="page-link text-success-emphasis"
					containerClassName="pagination text-success-emphasis"
					activeClassName="active text-success-emphasis"
					renderOnZeroPageCount={null}
				/>
			</Container>


		</Container>


	);
}