import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import Form from "react-bootstrap/Form";

export default function CertificateImport() {

	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);

	const [num_insertions,setNumInsertions] = useState(0);
	const [num_errors,setNumErrors] = useState(0);
	const [errors,setErrors] = useState(null);

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
	},[]);

	const handleImport = async (event) => {

		const answer = window.confirm("¿Seguro que quiere importar el excel a la base de datos y dar de baja los asegurados?");

		if (answer) {

			await axios.post('https://api-rnf.seguronline.es/certificate/importdown', event.target.files[0],{
				headers: {
					Authorization: `${cookies.token}`
				}}).then(({data})=>{

				if(!data.message) {
					alert("Error en los datos y/o excel incompatible.");
				}else{
					setNumInsertions(data.num_insertions);
					setNumErrors(data.num_errors);
					setErrors(data.errors);
				}

			}).catch(({response:{data}})=>{
				alert("Error. Formato de PDF incompatible con la App")
			});

		}
	};

	return (
		<Container>
			<br/>
			<h1>Baja masiva de asegurados</h1>
			<br/>
			<br/>
			<h2>Bajas</h2>
			<br/>
			<Container>
				<Row>
					<Col>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Importar excel para bajas:</Form.Label>
							<Form.Control type="file" onChange={handleImport}/>
						</Form.Group>
					</Col>
					<Col>
					</Col>
				</Row>
				<br/>
				<br/>
				{num_insertions ? <p>Número de bajas correctas: {num_insertions} <br/></p> : ""}
				{num_errors ? <p>Número de errores: { num_errors } <br/><br/>No se han podido dar de baja los siguientes asegurados: </p> : ""}
				{errors ? errors.map((error, index) => (<p>Nif: { error } <br/></p>))
					: ""}
				<br/>
				<Row>
					<Col>
						<br/>
					</Col>
				</Row>
			</Container>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
		</Container>
	);
}