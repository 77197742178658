import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function CertificateDown() {
	const {id} = useParams();

	const [ person_number, setPersonNumber ] = useState("");
	const [ name, setName ] = useState("");
	const [ nif, setNif ] = useState("");

	const [ dateDown, setDateDown ] = useState("");

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}

		fetchCertificate();
	},[]);


	const fetchCertificate = async () => {
		await axios.get(`https://api-rnf.seguronline.es/certificate/find/${id}`,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

			setPersonNumber(data[0].person_number);
			setName(data[0].name);
			setNif(data[0].nif);

		});
	};

	const downCertificate = async (e) => {
		e.preventDefault();

		const raw = {
			'down_date': dateDown,
			'down': 1,
		};

		await axios.patch(`https://api-rnf.seguronline.es/certificate/${id}`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

				axios.delete(`https://api-rnf.seguronline.es/certificate/pdf/delete/${person_number}`, {
					headers: {
						Authorization: `${cookies.token}`
					}}).then(({data})=>{
					navigate(`/certificate/${id}`)
				}).catch(({response:{data}})=>{
					alert("Error al dar de baja el certificado. Datos incorrectos");
				});

		}).catch(({response:{data}})=>{
			alert("Error al dar de baja el certificado. Datos incorrectos");
		});
	};

	const handleMassiveDown  = async (e) => {
		navigate("/certificate/importdown");
	};

	return (
		<Container>
			<br />
			<h2>Dar de baja un Certificado</h2>
			<br />
			<Button className="text-center" variant="secondary" onClick={handleMassiveDown}>Ir a baja masiva de asegurados</Button>
			<br />
			<br />
			<Form onSubmit={downCertificate}>
				<Row>
					<Col>
						<Form.Group controlId="person_number">
							<Form.Label>Nº persona:</Form.Label>
							<Form.Control disabled={true} type="text" value={person_number} onChange={(event) => {
								setPersonNumber(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="name">
							<Form.Label>Nombre:</Form.Label>
							<Form.Control disabled={true} type="text" value={name} onChange={(event) => {
								setName(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="nif">
							<Form.Label>NIF:</Form.Label>
							<Form.Control disabled={true} type="text" value={nif} onChange={(event) => {
								setNif(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/><br/>
				<h3>Dar de baja:</h3>
				<br/><br/>
				<Row>
					<Col>
						<Form.Group controlId="dateDown">
							<Form.Label>Fecha de baja del certificado:</Form.Label>
							<Form.Control type="date" value={dateDown} onChange={(event) => {
								setDateDown(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
					</Col>
				</Row>
				<br/>
				<Button variant="danger" className="mt-2" size="md" block="block" type="submit">
					Dar de baja
				</Button>
			</Form>
		</Container>
	);
}