import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {NavDropdown} from "react-bootstrap";
import {useCookies} from "react-cookie";
import {Link, useNavigate} from "react-router-dom";

export default function Menu() {

	const [cookies, setCookie, removeCookie] = useCookies(['user']);
	const navigate = useNavigate();

	const logout = async () => {
		removeCookie('token');
		navigate("/login");
	};

	const expedientIndex = async () => {
		navigate("/expedient");
	};

	const expedient = async () => {
		navigate("/expedient/create");
	};

	const newA = async () => {
		navigate("/certificate/create");
	};

	const downA = async () => {
		navigate("/certificate/importdown");
	};

	const updateA = async () => {
		navigate("/certificate/importupdate");
	};

	return (
		<>
			<Navbar bg="success" data-bs-theme="dark">
				<Container>
					<Navbar.Brand href="/" className="me-5"><img className="me-3" src="/logo-renfe-mya-horizontal2.png" />Muñiz y Asociados - Renfe App</Navbar.Brand>
					<Nav className="d-block w-100">
						<Nav.Link href="/" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Inicio</Nav.Link>
						<Nav.Link href="/downloads" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Descargas</Nav.Link>
						<NavDropdown title={'Alta nueva'} id="collasible-nav-dropdown" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}} className="">
							<NavDropdown.Item onClick={newA}>Altas</NavDropdown.Item>
							<NavDropdown.Item onClick={downA}>Bajas</NavDropdown.Item>
							<NavDropdown.Item onClick={updateA}>Modificaciones</NavDropdown.Item>
						</NavDropdown>
						{/*<Nav.Link href="/certificate/create" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Alta nueva</Nav.Link>*}
						{/*<Nav.Link href="/certificate/import" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Carga masiva</Nav.Link>*/}
						{/*<Nav.Link href="/expedient" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Siniestros</Nav.Link>*/}
						{/*<Nav.Link href="/reports" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Informes de siniestralidad</Nav.Link>*/}
						<NavDropdown title={'Siniestros'} id="collasible-nav-dropdown" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}} className="">
							<NavDropdown.Item onClick={expedientIndex}>Gestionar un siniestro</NavDropdown.Item>
							<NavDropdown.Item onClick={expedient}>Aperturar un siniestro</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title={'Usuario'} id="collasible-nav-dropdown" className="text-capitalize" style={{float:"right"}}>
							<NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
						</NavDropdown>
						<img className="me-1 mt-1" src="/user.png" style={{float:"right"}} />
					</Nav>

				</Container>
			</Navbar>
		</>
	);
}